<template>
  <div id="genomeBrowser" class="px-2">
    <div class="row">
      <div class="col col-12">
        <span>
          {{ mainText }}
        </span>
      </div>
    </div>
    <div v-if="statReady" id="statistics" class="text-muted">
      Last update
      <b>{{ lastUpdate || "" }}</b>:
      <span v-if="loading" class="material-icons spinning">hourglass_empty</span>
      <span v-if="!loading">
        {{ allMutations.length }} positions with mutations (based on
        <a :href="mainDataUrl + 'lineages/genomes.txt'" target="_blank"> {{ numberGenomes }}</a> genomes),
        {{ structures.features.length }} structures, and
        {{ pdbs.features.length }} models.
      </span>
    </div>
    <hr class="less-margin" />
    <div class="row">
      <div class="col-12">
        <form class="form-inline">
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomOut2D()" title="Zoom out">
            <span class="material-icons">zoom_out</span>
          </button>
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomIn2D()" title="Zoom in">
            <span class="material-icons">zoom_in</span>
          </button>
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomFit2D()" title="Zoom to fit">
            <span class="material-icons">zoom_out_map</span>
          </button>
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomSelection2D()"
            title="Zoom to selection">
            <span class="material-icons">fullscreen_exit</span>
          </button>
          <span class="side-margin">&#124;</span>
          Selection:
          <input class="form-control form-control-sm" type="text" placeholder="No selection"
            v-bind:value="selectedPositions" readonly />
          <button type="button" class="btn btn-sm btn-outline-dark" v-on:click="zoomFit2D()" title="Clear seletion">
            <span class="material-icons">cached</span>
          </button>
          <span class="side-margin">&#124;</span>
          Coloring:
          <select id="pl-schema-select">
            <option>Original</option>
            <option>Nucleotide</option>
            <option>Purine/pyrimidine</option>
            <option>None</option>
          </select>
          <!-- <span class="side-margin">&#124;</span>
          Lineage:
          <select class="form-control" id="currentLineage" name="currentLineage" size="1" v-model="currentLineage"
            @change="setLineage({})">
            <option v-for="value in lineages" :key="value" :value="value">
              {{ value }}
            </option>
          </select>

          <label class="form-check-label small" for="useLineageMutationsChk">
            Use only selected lineage for coloring of 3D structures</label>
          <input class="form-check-input" type="checkbox" value="" id="useLineageMutationsChk"
            v-model="useLineageMutations" /> -->
        </form>
      </div>
    </div>
    <div id="protaelContainer"></div>
    <div class="row alert alert-secondary" v-if="loading">
      <span class="material-icons spinning">hourglass_empty</span> Please wait
      while mutation data is loading...
    </div>
    <ul class="nav nav-tabs" id="infoTabs" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="struct-tab" data-toggle="tab" href="#struct" role="tab" aria-controls="struct"
          aria-selected="true">3D View</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="proteins-tab" data-toggle="tab" href="#proteinsList" role="tab"
          aria-controls="proteinsList" aria-selected="false">Proteins</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="list-tab" data-toggle="tab" href="#list" role="tab" aria-controls="list"
          aria-selected="false">Structures</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="listTempl-tab" data-toggle="tab" href="#listTempl" role="tab" aria-controls="listTempl"
          aria-selected="false">Models</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="mut-tab" data-toggle="tab" href="#mutlist" role="tab" aria-controls="mutlist"
          aria-selected="false">Nucleotide variants</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="struct" role="tabpanel" aria-labelledby="struct-tab">
        <threeDMolWrapper v-if="selectedPDB != null && !loading" :loading="loading" :showRandomSelectBox="true"
          :enablePositionSelection="false" :mappedPosition="mappedPosition" :clickedResidue="clickedResidue"
          :selectedPDB="selectedPDB" :mutations="mutationsInRange(selectedPDB.start, selectedPDB.end)"
          :mutationCounts="mutationCounts" :extraColoring="extraColoring"></threeDMolWrapper>

        <ProteinInfo v-if="selectedProtein" v-bind:protein="selectedProtein" v-bind:structures="featuresInRange(
            structures.features,
            selectedProtein.start,
            selectedProtein.end
          )
            " v-bind:models="featuresInRange(
            pdbs.features,
            selectedProtein.start,
            selectedProtein.end
          )
            "></ProteinInfo>
        <hr />
        <div class="container-fluid" style="margin-top: 20px" v-if="!loading">
          <VariantsTable v-if="selectedPDB != null" v-bind:feature="selectedPDB" v-bind:mutations="mutationsInRange(selectedPDB.start, selectedPDB.end)
            "></VariantsTable>
          <VariantsTable v-if="selectedProtein != null" v-bind:feature="selectedProtein" v-bind:mutations="mutationsInRange(selectedProtein.start, selectedProtein.end)
            "></VariantsTable>
        </div>
      </div>
      <div class="tab-pane fade" id="proteinsList" role="tabpanel" aria-labelledby="proteins-tab">
        <div id="proteinsDiv">
          <ProteinsTable v-bind:proteins="proteins"></ProteinsTable>
          <!-- <hr />
          <ProteinsTable v-bind:proteins="nspProteins" v-bind:isnsps="true"></ProteinsTable>-->
        </div>
      </div>
      <div class="tab-pane fade" id="list" role="tabpanel" aria-labelledby="list-tab">
        <div id="structList">
          <StructuresTable v-bind:structures="structures.features" v-bind:templates="false"></StructuresTable>
        </div>
      </div>
      <div class="tab-pane fade" id="listTempl" role="tabpanel" aria-labelledby="listTempl-tab">
        <div id="pdbList">
          <StructuresTable v-bind:structures="pdbs.features" v-bind:templates="true"></StructuresTable>
        </div>
      </div>
      <div class="tab-pane fade" id="mutlist" role="tabpanel" aria-labelledby="mut-tab">
        <div id="mutList">
          <VariantsTable v-bind:pdb="null" v-bind:mutations="allMutations"></VariantsTable>
        </div>
      </div>
    </div>

    <hr class="less-margin" />

    <div class="row" style="margin: 40px"></div>
  </div>
</template>

<script>
import Workers from "@/workers";
import Shared from "@/shared/Shared.js";
export default {
  name: "app",
  mixins: [Shared],
  components: {
    threeDMolWrapper: () => import("../components/3DMolWrapper"),
    StructuresTable: () => import("../components/StructuresTable"),
    ProteinInfo: () => import("../components/ProteinInfo"),
    ProteinsTable: () => import("../components/ProteinsTable"),
    VariantsTable: () => import("../components/VariantsTable"),
  },
  data: function () {
    return {
      START: Date.now(),
      //// input files and links:
      mainDataUrl: '',
      maintextFile: '../maintext.md', // markdown of the main text for the page
      previewFile: './preview.json', // data for preview, contains prooteins, structures, models, genomes count
      fasta: "./static/sequence.fasta", // genbank fasta
      // featuresFile: "./static/feature_table.txt", 
      modelsTable: "./models/models_.csv",
      // csv files are not used for data parsing, but nare needed for download links
      proteinsTable: "./proteins/proteins.csv",
      structureTable: "./structures/structures.csv",
      // end of csv files
      lineageFolder: "./lineages",
      lineageListFile: "./lineages/list.txt", // file with list of filenames of mutation data per lineage
      mutationsFiles: [],//used to be hard-coded, now shoud be read from lineageListFile
      dateFile: "./lastupdate",
      lineages: [],
      currentLineage: "",
      useLineageMutations: false,
      // constants
      methods: {
        // colors for different methods
        "X-RAY DIFFRACTION": "#0000DD",
        "ELECTRON MICROSCOPY": "#05986d",
        "SOLUTION NMR": "#e90f3e",
        "ALPHAFOLD": "#FFA500"
      },
      //// reactive data
      mainText: '',
      pdbs: { features: [] }, // original pdbs,
      structures: { features: [] }, // covid-19 pdbs,
      nsps: { features: [] }, // proteins,
      // next one repeats information from nsps, will think about this later
      proteins: [],
      protaelJson: {
        // generated json for protael
        showSequence: false,
        seqcolors: {
          colors: {},
          data: "",
        },
        markers: [],
        bridges: [],
        qtracks: [],
        overlayfeatures: {
          label: "regions",
          showLabels: "true",
          features: [],
        },
        ftracks: [],
      },
      selectedPDB: {
        // selected structure
      },
      selectedProtein: {},
      protael: null, // protael
      lineageMutations: {
        // lineage name: [ mutations ]
        other: [
          // drop this after real lineage data is there
          // AA mutations
          {
            pos: 0,
            count: 0,
            gene: "",
            mtype: "",
            atype: "",
            bchange: "",
            pchange: "",
          },
        ],
      },
      mutations: [
        // AA mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ],
      allMutations: [
        // all mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ],
      mutationCounts: [],
      avgMutationCounts: [],
      mAtoms: {},
      selectedPositions: "",
      clickedResidue: -1,
      mappedPosition: -1,
      loading: true, //is data still loading?,
      lastUpdate: "",
      numberGenomes: 0,
      statReady: false,
      acceptableChangesRegex: /([ARNDCQEGHILKMFPSTWYV])>([ARNDCQEGHILKMFPSTWYV])/gi, // regex to test to acceptable mutations for avg count charts
      extraColoring: {}// key: value pairs for extra coloring. Value is object = {label:'Abc', function(resNum){return color)}}
    };
  },
  watch: {
    tracksLoaded: function (num) {
      this.log(`Qtracks loaded: ${num}`);
    },
  },
  mounted: function () {
    // eslint-disable-next-line no-console
    // console.log(`mounted: ${JSON.stringify(this.$route.params)}`)
    this.mainDataUrl = this.getDataApiUrl()
      // + this.getUrlPrefix()
      + this.dataUrlFromParams(this.$route.params);
  },
  async beforeRouteUpdate(to) {
    // eslint-disable-next-line no-console
    // console.log(`GB updated: ${JSON.stringify(to.params)}`)

    await this.saveDataUrl(to.params)
  },
  async beforeRouteEnter(to, from, next) {
    // eslint-disable-next-line no-console
    // console.log(`GB entered: ${JSON.stringify(to.params)}`)
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called
    // console.log(from)
    next(async vm => {
      await vm.saveDataUrl(to.params)
    })
  },
  computed: {
    tracksLoaded: function () {
      return (this.protael && this.protael.protein && this.protael.protein.qtracks) ? this.protael.protein.qtracks.length : 0;
    },
  },
  methods: {
    is404(text) {
      return text.startsWith("<!DOCTYPE html>");
    },
    async saveDataUrl(params) {

      window.jQuery("#protaelContainer").empty();
      this.loading = true;
      // eslint-disable-next-line no-console
      console.log(`saveDataUrl: ${JSON.stringify(params)}`)
      this.mainDataUrl = this.getDataApiUrl()
        // + this.getUrlPrefix()
        + this.dataUrlFromParams(params);

      this.$nextTick(async function () {
        await this.loadData();
      })
    },
    async loadData() {
      // eslint-disable-next-line no-console
      console.log('loading data:');
      this.clear();
      await this.loadSequence(this.mainDataUrl + this.fasta);
      await this.loadPreviewFile(this.mainDataUrl + this.previewFile);
      await this.loadUpdateDate(this.mainDataUrl + this.dateFile);
      await this.loadMainText(this.mainDataUrl + this.maintextFile);
      this.display();
      this.log("View created");

      this.$nextTick(async function () {
        await this.loadLineageList(this.mainDataUrl + this.lineageListFile);

        // eslint-disable-next-line no-console
        console.log('1:');
        this.afterInits();

        this.statReady = true;
        // eslint-disable-next-line no-console
        console.log('2:');
        this.init3DViewer();
        this.selectRandomPDB();
      });
    },
    clear() {
      this.protael = null;
      // reset data
      this.lineages = [];
      this.currentLineage = "";
      this.useLineageMutations = false;
      this.pdbs = { features: [] };
      this.structures = { features: [] };
      this.nsps = { features: [] };
      this.proteins = [];
      this.protaelJson = {
        // generated json for protael
        showSequence: false,
        seqcolors: {
          colors: {},
          data: "",
        },
        markers: [],
        bridges: [],
        qtracks: [],
        overlayfeatures: {
          label: "regions",
          showLabels: "true",
          features: [],
        },
        ftracks: [],
      };
      this.selectedPDB = {
        // selected structure
      };
      this.extraColoring = {};
      this.selectedProtein = {};
      this.lineageMutations = {
        // lineage name: [ mutations ]
        other: [
          // drop this after real lineage data is there
          // AA mutations
          {
            pos: 0,
            count: 0,
            gene: "",
            mtype: "",
            atype: "",
            bchange: "",
            pchange: "",
          },
        ],
      };
      this.mutations = [
        // AA mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ];
      this.allMutations = [
        // all mutations
        {
          pos: 0,
          count: 0,
          gene: "",
          mtype: "",
          atype: "",
          bchange: "",
          pchange: "",
        },
      ];
      this.mutationCounts = [];
      this.avgMutationCounts = [];
      this.mAtoms = {};
      this.selectedPositions = "";
      this.clickedResidue = -1;
      this.mappedPosition = -1;
      this.loading = true;
      this.lastUpdate = "";
      this.numberGenomes = 0;
      this.statReady = false;
      this.protaelJson = {};
    },
    mutationsInRange(start, end) {
      if (this.useLineageMutations && this.lineageMutations[this.currentLineage]) {
        return this.lineageMutations[this.currentLineage].filter((m) => {
          return m.variant >= start && m.variant <= end;
        });
      }
      return this.mutations.filter((m) => {
        return m.variant >= start && m.variant <= end;
      });

    },
    featuresInRange(featurelist, start, end) {
      // returns features overlapping with given range
      return featurelist.filter((f) => {
        return (
          (f.start >= start && f.start <= end) ||
          (f.end >= start && f.end <= end)
        );
      });
    },
    log: function (msg) {
      const t = ((Date.now() - this.START) / 1000).toFixed(2);
      // eslint-disable-next-line no-console
      console.log(`${t}ms: ${msg}`);
    },
    //// DATA LOADING
    async loadUpdateDate(url) {
      const response = await fetch(url);
      const results = await response.text();
      this.lastUpdate = results.split("\n")[0].trim();
    },
    async loadMainText(url) {
      const response = await fetch(url);
      const results = await response.text();
      this.mainText = results.trim();
    },
    async loadSequence(url) {
      try {
        const response = await fetch(url);
        const results = await response.text();
        let lines = results.split("\n");
        let seq = "";
        for (let i = 1; i < lines.length; i++) seq += lines[i].trim();

        this.protaelJson.sequence = seq;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    loadGenes(url) {
      let self = this;

      window.jQuery.ajax({
        url: url,
        success: function (results) {
          self.protael.addFTrack(results);
        },
        async: false,
      });
    },
    processProteins(proteins) {
      let self = this;
      let track = {
        label: "Proteins",
        allowOverlap: false,
        showLine: true,
        display: "arrow",
        color: "#e90f3e",
        features: [],
        minGap: 400,
      };

      let hasStrands = false;

      proteins.forEach((protein) => {
        let p, q;
        {
          // see if this is format with strand
          hasStrands = protein.strand !== undefined;

          let strand = protein.strand;
          q = {
            name: protein.name,
            id: "protein_" + protein.label,
            label: protein.label,
            start: protein.start,
            end: protein.end,
            funct: protein.funct,
            clevsite: protein.clevsite,
            polyprotloc: protein.polyprotloc,
            type: "protein", // for display switch
            reverse: protein.reverse,
            tm: JSON.parse(JSON.stringify(protein.tm).toLowerCase())
          };

          p = {
            label: q.label,
            id: q.id,
            start: q.start,
            end: q.end,
            color: "#e90f3e",
            clazz: "protein-feature",
            reverse: protein.reverse,
            properties: {
              Name: q.name.replace("~", "&apos;"),
              "Putative function": q.funct.replace("~", "&apos;"),
              "Genomic start-end": q.start + " - " + q.end,
              "C-terminal cleavage site": q.clevsite.replace("~", "&apos;"),
            },
          };
          if (strand) {
            q.strand = strand;
            p.properties.Strand = strand;
          }

          if (q.polyprotloc != "n/a") p["Polyprotein start-end"] = q.polyprotloc;

          if (q.label === "orf1ab") {
            p.color = "orange";
            p.properties["Description"] = "Boundaries of the orf1ab gene";
          }
        }

        self.proteins.push(q);
        track.features.push(p);
      });

      // set display type to line if needed
      if (!hasStrands) track.display = "line";
      self.nsps = track;

      self.log("Adding proteins");

      if (!self.protaelJson.ftracks)
        self.protaelJson.ftracks = [];
      self.protaelJson.ftracks.push(self.nsps);
    },
    async loadLineageList(listUrl) {
      /** read file with filenames of mutaions data
       * firs non-commenf line contains all lineages' files
       */
      const self = this;
      let isAllLineages = true;

      await window.d3.text(listUrl).then(async function (text) {
        let lines = text.split("\n");
        lines.forEach((l) => {
          if (!l.startsWith("#")) {
            if (isAllLineages) {
              self.mutationsFiles = l.split(",").map((f) => self.lineageFolder + "/" + f.trim() + ".tsv");
              isAllLineages = false;
            } else {
              self.lineages.push(l.trim());
            }
          }
        });

        window.jQuery("body").addClass("waiting");

        const startTime = Date.now();
        const acceptableChangesRegex = /([ARNDCQEGHILKMFPSTWYV])>([ARNDCQEGHILKMFPSTWYV])/gi; // regex to test to acceptable mutations for avg count charts

        let allData = [];

        for await (const url of self.mutationsFiles) {
          let data = await window.d3
            .tsv(self.mainDataUrl + url, function (d) {
              const cnt = (d["AA change"].match(acceptableChangesRegex) || [])
                .length;

              let m = {
                variant: +d["Genome position"], //+d.variant,
                refRegion: d["Gene/Protein name"], //d.refRegion,
                mutationNumber: +d["Virus count for missense mutations"], //+d.mutationNumber,
                mutationType: d["Mutation types"], //d.mutationType,
                annotationType: d["Annotation types"], //d.annotationType,
                alt: d["Base change:Virus count"], //d.alt,
                proteinAminoAcids: d["AA change"], //d.proteinAminoAcids,
                aaMutationCount: +cnt || 0,
              };
              return m;
            })
          allData = allData.concat(data)
        }

        const elaspedTime = Date.now() - startTime;
        self.log(`Mutations downloaded after ${elaspedTime}ms`);
        self.allMutations = allData;

        const nonSynMutations = allData.filter(
          (m) => m.proteinAminoAcids.indexOf(">") > 0
        );

        self.mutations = Object.freeze(nonSynMutations);
        self.loading = false;

        // use for coloring
        self.protael.addQTrack(
          self.mutationsToChart(
            self.mutations,
            "Mutations",
            "Virus counts of protein mutations"
          )
        );

        // array with mut.count for each position
        self.mutationCounts = new Array(self.protaelJson.sequence.length);
        self.mutationCounts.fill(0);
        self.mutations.forEach(
          (m) => (self.mutationCounts[m.variant - 1] = m.aaMutationCount)
        );

        const win = 100;
        // next function calls worker to generate qtrack
        self.addAvgMutationsChart(
          self.mutationCounts,
          "MutRate",
          `Rate of protein mutations (${win}nt average)`,
          win
        );

        self.log("Mutations chart created");
        self.afterChartInit();
        window.jQuery("body").removeClass("waiting");
        // self.loadMutationsTsv(self.mutationsFiles);
      });
    },
    async loadMutationsTsv(urls) {
      const self = this;
      window.jQuery("body").addClass("waiting");

      const startTime = Date.now();
      const acceptableChangesRegex = /([ARNDCQEGHILKMFPSTWYV])>([ARNDCQEGHILKMFPSTWYV])/gi; // regex to test to acceptable mutations for avg count charts


      let allData = [];

      for await (const url of urls) {
        let data = await window.d3
          .tsv(this.mainDataUrl + url, function (d) {
            const cnt = (d["AA change"].match(acceptableChangesRegex) || [])
              .length;

            let m = {
              variant: +d["Genome position"], //+d.variant,
              refRegion: d["Gene/Protein name"], //d.refRegion,
              mutationNumber: +d["Virus count for missense mutations"], //+d.mutationNumber,
              mutationType: d["Mutation types"], //d.mutationType,
              annotationType: d["Annotation types"], //d.annotationType,
              alt: d["Base change:Virus count"], //d.alt,
              proteinAminoAcids: d["AA change"], //d.proteinAminoAcids,
              aaMutationCount: +cnt || 0,
            };
            return m;
          })
        allData = allData.concat(data)
      }

      const elaspedTime = Date.now() - startTime;
      self.log(`Mutations downloaded after ${elaspedTime}ms`);
      self.allMutations = allData;

      const nonSynMutations = allData.filter(
        (m) => m.proteinAminoAcids.indexOf(">") > 0
      );

      self.mutations = Object.freeze(nonSynMutations);
      self.loading = false;

      // use for coloring
      self.protael.addQTrack(
        self.mutationsToChart(
          self.mutations,
          "Mutations",
          "Virus counts of protein mutations"
        )
      );

      // array with mut.count for each position
      self.mutationCounts = new Array(self.protaelJson.sequence.length);
      self.mutationCounts.fill(0);
      self.mutations.forEach(
        (m) => (self.mutationCounts[m.variant - 1] = m.aaMutationCount)
      );

      const win = 100;
      // next function calls worker to generate qtrack
      self.addAvgMutationsChart(
        self.mutationCounts,
        "MutRate",
        `Rate of protein mutations (${win}nt average)`,
        win
      );

      self.log("Mutations chart created");
      self.afterChartInit();
      window.jQuery("body").removeClass("waiting");
    },
    async loadLineage(title) {
      const self = this;
      window.jQuery("body").addClass("waiting");
      const startTime = Date.now();
      window.d3
        .tsv(self.lineageFolder + "/" + title + ".tsv", function (d) {
          let m = {
            variant: +d["Genome position"], //+d.variant,
            refRegion: d["Gene/Protein name"], //d.refRegion,
            mutationNumber: +d["Virus count for missense mutations"], //+d.mutationNumber,
            mutationType: d["Mutation types"], //d.mutationType,
            annotationType: d["Annotation types"], //d.annotationType,
            alt: d["Base change:Virus count"], //d.alt,
            proteinAminoAcids: d["AA change"] || "", //d.proteinAminoAcids,
          };
          // find all ";" chars, and add 1 to count
          m.aaMutationCount =
            (m.proteinAminoAcids.match(/;/g) || []).length + 1;
          return m;
        })
        .then(function (data) {
          const elaspedTime = Date.now() - startTime;
          self.log(`Lineage data downloaded after ${elaspedTime}ms`);
          self.lineageMutations.other && delete self.lineageMutations.other;
          self.lineageMutations[title] = data;
          self.protael.updateQTrack(
            "lineageMutations",
            self.mutationsToChart(
              data,
              "lineageMutations", //title,
              `Virus counts of protein mutations  @${title}`,
              "#006644"
            )
          );

          self.afterChartInit();
        })
        .finally(function () {
          window.jQuery("body").removeClass("waiting");
        });
    },
    processModels(models) {
      let self = this;

      let track = {
        label: "Models",
        allowOverlap: false,
        showLine: true,
        display: "line",
        color: "#e90f3e",
        features: [],
      };

      models.forEach((model) => {

        let pdbid = model.pdbid;

        let p = {
          //id start end seqid ali name method url
          label: pdbid,
          start: model.start,
          end: model.end,
          color: "#e90f3e",
          clazz: "pdb-feature",
          id: "pdb_" + pdbid, //.substring(0, 4),
          chain: pdbid.substring(4), // make sure we have only single-letter chains!
          url:
            "./models/" + pdbid.substring(0, 4).toUpperCase() + ".pdb",
          isModel: true,
          type: "pdb",
          properties: {
            Title: model.name,
            pdbid: model.method === "ALPHAFOLD" ? "" : pdbid.substring(0, 4),
            "PDB structure": pdbid,
            Method: model.method,
            "Sequence identity": model.seqidentity + "%",
            alignment_start: model.alistart,
          },
        };
        let color = self.methods[p.properties.Method];
        color && (p.color = color);

        track.features.push(p);


      });
      self.pdbs = track;

      self.log("Adding models");

      if (!self.protaelJson.ftracks)
        self.protaelJson.ftracks = [];
      self.protaelJson.ftracks.push(self.pdbs);
    },
    processStructures(structures) {
      let self = this;

      let track = {
        label: "Structures",
        allowOverlap: false,
        showLine: true,
        display: "line",
        color: "#e90f3e",
        features: [],
        minGap: 600,
      };

      structures.forEach((struct) => {

        const pdbid = struct.pdbid;
        const pdbCode = pdbid.substring(0, 4);

        let p = {
          label: pdbid,
          start: struct.start,
          end: struct.end,
          color: "#e90f3e",
          clazz: "structure-feature",
          id: "struct_" + pdbid,
          chain: pdbid.substring(4), // make sure we have only single-letter chains!
          isModel: false,
          url: "structures/" + pdbCode.toUpperCase() + ".pdb",
          isCSGID: struct.isCsgid === "true",
          type: "pdb",
          properties: {
            Title: struct.name,
            pdbid: pdbCode,
            "PDB structure": pdbid,
            Method: struct.method,
            alignment_start: struct.alistart
          },
        };

        if (p.isCSGID) {
          p.properties.comment = "Solved by CSGID";
          p.clazz = "structure-feature csgid";
        }

        let color = self.methods[p.properties.Method];
        color && (p.color = color);

        track.features.push(p);
      });
      self.structures = track;
      self.log("Adding structures");

      if (!self.protaelJson.ftracks)
        self.protaelJson.ftracks = [];
      self.protaelJson.ftracks.push(self.structures);
    },
    async loadPreviewFile(url) {
      const self = this;
      const response = await fetch(url);
      const results = await response.json();

      self.processProteins(results.proteins);
      self.processStructures(results.structures);
      self.processModels(results.models);
      this.numberGenomes = results.genomes;
    },

    //// DISPLAY
    setLineage() {
      this.currentLineage && this.loadLineage(this.currentLineage);
    },

    mutationsToChart(mutations, id, label, color = "blue", scale = "log10") {
      const l = this.protaelJson.sequence.length;
      // let max = 0;
      let qtrack = {
        id: id,
        label: label,
        color: color,
        type: "bar",
        values: new Array(l),
        transform: scale,
        forceNonZero: true,
        clazz: "shadow c3d-mutations",
        tooltipPrefix: "Count",
      };
      qtrack.label += " [" + scale + " scale]";
      qtrack.values.fill(0);
      mutations &&
        mutations.forEach((m) => {
          qtrack.values[m.variant - 1] = m.mutationNumber;
        });

      return qtrack;
    },
    /**
     * Calculate sliding average over data, create qtrack
     */
    async addAvgMutationsChart(
      mutations,
      id,
      label,
      requestedWindow,
      color = "#f2072e"
    ) {
      if (!mutations || mutations.length === 0) {
        this.log(`No data provided for averaging`);
        return;
      }
      const self = this;
      requestedWindow = requestedWindow || 100;

      let qtrack = {
        id: id,
        label: label,
        color: color,
        type: "bar",
        values: new Array(this.protaelJson.sequence.length),
        // transform: "log10",
        forceNonZero: true,
        clazz: "shadow c3d-mutations-avg",
        tooltipPrefix: "Rate",
      };

      Workers.qtrackWorker.onmessage = (event) => {
        // self.log(event.data);
        qtrack = event.data;
        self.avgMutationCounts = [...qtrack.values];
        self.protael.updateQTrack(id, qtrack);
        self.log("AvgMutations chart ready");
      };
      Workers.qtrackWorker.postMessage({
        data: mutations,
        qtrack: qtrack,
        win: requestedWindow,
      });
    },
    async display() {
      let self = this;
      // eslint-disable-next-line no-unused-vars
      let Snap = window.Snap;
      // eslint-disable-next-line no-unused-vars
      Snap.plugin(function (Snap, Element, Paper, glob) {
        // eslint-disable-next-line no-unused-vars
        Element.prototype.dragVertical = function () { };
      });

      // eslint-disable-next-line no-unused-vars
      self.protael = window.Protael(
        self.protaelJson,
        "protaelContainer",
        false
      );
      self.protael.onSelectionChange(function () {
        self.selectedPositions = self.protael.getSelection().join(":");
      });

      self.protael.onSelectionEnd(function () {
        const sel = self.protael.getSelection();
        if (sel[0] === sel[1]) return;
        self.protael.zoomToSelection();
      });

      self.protael.draw();

      // have to call it after draw()
      self.protael.onClick(function (e) {
        e.stopImmediatePropagation();
        e.stopPropagation();
        const x = self.protael.toOriginalX(self.protael.mouseToSvgXY(e).x);

        self.clickedResidue = x;
        self.selectPDBAt(x);
        self.selectedPositions = self.protael.getSelection().join(":");
        self.labelResidue(x);
      });
    },
    async afterInits() {
      let self = this;
      window
        .jQuery(".pl-feature.pdb-feature")
        .mousedown(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
        })
        .click(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          let id = window.jQuery(this).attr("id");
          self.selectPDB(id, 1);
        });
      window
        .jQuery(".pl-feature.structure-feature")
        .mousedown(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
        })
        .click(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          let id = window.jQuery(this).attr("id");
          self.selectPDB(id, 2);
        });
      window
        .jQuery(".pl-feature.protein-feature")
        .mousedown(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
        })
        .click(function (event) {
          event.stopImmediatePropagation();
          event.stopPropagation();
          let id = window.jQuery(this).attr("id");
          self.selectProtein(id);
        });
      window.jQuery("#pl-schema-select").change(function () {
        self.protael.setColoringScheme(window.jQuery(this).val());
      });
    },

    afterChartInit() {
      const self = this;
      window.jQuery(".c3d-mutations").mousemove(function (e) {
        let chart = window.jQuery(this);
        const pos = self.protael.toOriginalX(self.protael.mouseToSvgXY(e).x);
        const mutation = self.selectMutationAt(pos);
        let datad = chart.is("[data-tooltip]")
          ? JSON.parse(chart.attr("data-tooltip"))
          : {};
        datad["Position"] = pos;
        datad["Virus count"] = mutation ? mutation.mutationNumber : "0";
        datad["Mutations"] = mutation ? mutation.alt : "0";
        datad["AA changes"] = mutation
          ? mutation.proteinAminoAcids
          : "No changes";
        chart.attr({ "data-tooltip": JSON.stringify(datad) });
      });
    },
    //// SELECTION
    clearSelection() {
      // remove selection
      let current;
      if (this.selectedPDB && this.selectedPDB.id) {
        current = window.Snap.select("#" + this.selectedPDB.id);
      } else if (this.selectedProtein && this.selectedProtein.id) {
        current = window.Snap.select("#" + this.selectedProtein.id);
      }
      current && current.removeClass("selected");
      this.selectedPDB = null;
      this.selectedProtein = null;
      this.extraColoring = {};
    },
    selectProtein(id) {
      // when user clicks on protein feature
      this.clearSelection();
      this.log("Selecting: " + id);

      let list = this.proteins.filter((p) => p.id === id);

      if (list && list.length) this.selectedProtein = list[0];

      if (!this.selectedProtein) return;

      window.Snap.select("#" + id).addClass("selected");
    },
    selectPDB(pdbid, idType) {
      // when user clicks on structure or model
      this.clearSelection();
      this.log("Selecting: " + pdbid);

      let pdbs =
        idType === 1
          ? this.pdbs.features.filter((p) => p.id === pdbid)
          : this.structures.features.filter((p) => p.id === pdbid);

      if (pdbs && pdbs.length) this.selectedPDB = pdbs[0];

      if (!this.selectedPDB) return;

      //protein that covers this structure
      let plist = this.findFeaturesByPosition(this.proteins, this.selectedPDB.start);
      if (plist.length > 0) {
        let p = plist[0];
        if (p.label.toLowerCase() === "poly" && plist.length > 1) {
          p = plist[1];
        }
        const tms = p.tm;
        let coloring = {}

        if (tms && Object.keys(tms).length > 0)
          coloring['tmhelix'] = {
            data: tms,
            legend: {
              tmhelix: { color: "#ff0000", label: "TMHelix" },
              inside: { color: "#a6cee3", label: "Inside" },
              outside: { color: "#fddaec", label: "Outside" },
              na: { color: "#d9d9d9", label: "No data" }
            },
            label: "Transmembrane helix prediction",
            func: function (atom, tmdata, tmcolors) {
              // find residue in tm data
              const resnum = atom.resi;
              for (const [tm, ranges] of Object.entries(tmdata)) {
                // tm is one of the in/out/tm, ranges is array of ranges
                for (const range of ranges) {
                  if (resnum >= range[0] && resnum <= range[1]) {


                    if (!tmcolors[tm.toLowerCase()])
                      // eslint-disable-next-line no-console
                      console.log(tmcolors[tm.toLowerCase()].color)
                    return tmcolors[tm.toLowerCase()].color;
                  }
                }
              }
              // return gray if not found
              return tmcolors.na.color;
            }
          };
        // update only once, in case we will add some other coloring later
        this.extraColoring = coloring;
      }

      window.Snap.select("#" + pdbid).addClass("selected");
    },
    selectRandomPDB() {
      const pdbs = this.structures.features;
      if (pdbs && pdbs.length) {
        const i = Math.floor(Math.random() * pdbs.length);
        this.selectPDB(pdbs[i].id, 2);
      }
    },
    // find feature that covers this position. returns sorted by start array of features
    findFeaturesByPosition(features, pos) {
      const compare = function (a, b) {
        return a.start > b.start;
      };
      return features.filter((f) => f.start <= pos && f.end >= pos).sort(compare);
    },
    selectPDBAt(pos) {
      if (
        this.selectedPDB &&
        this.selectedPDB.start <= pos &&
        this.selectedPDB.end >= pos
      ) {
        return;
      }
      if (
        this.selectedProtein &&
        this.selectedProtein.start <= pos &&
        this.selectedProtein.end >= pos
      ) {
        return;
      }

      let list = this.findFeaturesByPosition(this.structures.features, pos);
      if (list.length > 0) {
        this.selectPDB(list[0].id, 2);
        return;
      }

      list = this.findFeaturesByPosition(this.pdbs.features, pos);
      if (list.length > 0) {
        this.selectPDB(list[0].id, 1);
        return;
      }

      list = this.findFeaturesByPosition(this.nsps.features, pos);
      if (list.length > 0) {
        let id = list[0].id;
        // (in case of orf1ab priority goes to smaller proteins)
        if (id === "protein_orf1ab" && list.length > 1) id = list[1].id;
        this.selectProtein(id, 1);
        return;
      }
    },
    selectMutationAt(pos) {
      const list = this.mutations.filter((m) => m.variant === pos);
      return list[0];
    },
    hightlightTableRow(x, dx = 0) {
      window
        .jQuery(".table-variants tr.table-warning")
        .removeClass("table-warning");
      window.jQuery(".table-variants tr." + x).addClass("table-warning");
      if (dx > 0) {
        // if mutation is in codon, but not in the exact position:
        window
          .jQuery(".table-variants tr." + (x + 1))
          .addClass("table-warning");
        window
          .jQuery(".table-variants tr." + (x + 2))
          .addClass("table-warning");
      }
    },
    labelResidue(x, dx = 0) {
      if (x < 0) return;
      if (this.selectedProtein) {
        this.hightlightTableRow(x, dx);
        return;
      }

      if (this.selectedPDB) {
        const s = this.selectedPDB.start;
        const e = this.selectedPDB.end;
        const f = this.selectedPDB.properties.alignment_start;

        this.mappedPosition = this.dna2prot(x, s, e, f);

        if (this.mappedPosition && this.mappedPosition > 0) {
          this.hightlightTableRow(x, dx);
        }
      }
    },
    zoomTo(pos) {
      this.protael.setSelection(pos, pos);
      this.protael.zoomToSelection();
      this.protael.setSelection(pos, pos);
      this.labelResidue(pos);
    },
    zoomToCodon(pos) {
      this.protael.setSelection(pos, pos + 2);
      this.protael.zoomToSelection();
      this.protael.setSelection(pos, pos + 2);
      this.labelResidue(pos, 1);
    },
    zoomOut2D() {
      this.protael && this.protael.zoomOut();
    },
    zoomIn2D() {
      this.protael && this.protael.zoomIn();
    },
    zoomFit2D() {
      this.protael && this.protael.zoomToFit();
    },
    zoomSelection2D() {
      this.protael && this.protael.zoomToSelection();
    },
    clearSelection2D() {
      this.protael && this.protael.clearSelection();
    },
    ////// 3D only
    init3DViewer() {
      const self = this;
      //use first structure or model to display on load
      let feature = null;
      let idType = 2; // structure
      if (self.structures.features && self.structures.features[0]){
        feature = self.structures.features[0];
      }
      else if (self.pdbs.features && self.pdbs.features[0]){
        feature = self.pdbs.features[0];
        idType = 1; // model
      }

      if (feature) {
        const pdb = feature.id;

        self.selectPDB(pdb, idType);
      } else {
        self.log("No structures or models to display");
      }
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-top: 0px;
}

body.waiting * {
  cursor: progress !important;
}

.pl-feature-label {
  font-size: 12px;
  text-anchor: middle;
}

.pl-feature.selected rect {
  stroke: magenta;
  stroke-width: 10px;
}

.selected-pdb {
  border-bottom: 6px magenta solid;
}

#structureView {
  /* width: 100%;
  height: 100%; */
  position: relative;
}

svg .pl-ftrack-label {
  font-size: 18px;
}

svg .pl-chart-label {
  font-size: 18px;
}

.marks {
  font-size: 6px;
  fill: blue;
  /* baseline-shift: super; */
}

hr.less-margin {
  margin-top: 0.2 rem;
  margin-bottom: 0.1rem;
}

button.btn.btn-sm {
  padding: 4px 5px 0px 5px;
  margin-left: 2px;
}

.spinning {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>

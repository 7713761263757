<template>
  <div id="home" class="mx-4 px-2 pb-5">
    <div v-if="overviewData != null">

      <nav class="nav nav-tabs nav-fill" id="famTabs">
        <li class="nav-item">Viral order:</li>
        <li class="nav-item" v-for="family in overviewData.families" :key="family.label">
          <a class="nav-link" :class="{ active: activeFamilyLbl === family.label.toLowerCase() }"
            :id="family.label.toLowerCase() + '-tab'" data-toggle="tab" :href="'#' + family.label.toLowerCase()"
            role="tab" :aria-controls="family.label.toLowerCase()" aria-selected="true"
            @click="activeFamilyLbl = family.label.toLowerCase()">{{ family.name }}</a>
        </li>
      </nav>

      <div class="row mt-4" v-for="virus in activeFamily.species" :key="virus.label">
        <div class="col-md-2 col-sm-12">
          <h4>{{ virus.name }} ({{ virus.label.toUpperCase() }})</h4>
          <span class="badge badge-light" v-for="xref in virus.xrefs" :key="xref.label">
            <a :href="xref.url" :title="xref.name" target="_blank">
              {{ xref.name }}&nbsp;<sup><span class="material-icons tiny">
                  open_in_new
                </span></sup>
              <!-- <img :src="xref.icon" :alt="xref.name" class="img-fluid"> -->
            </a>
          </span>

        </div>

        <div :class="virus.segments.length === 1 ? 'col-md-9' : virus.segments.length === 2 ? 'col-md-4' : 'col-md-3'"
          class=" col-sm-12 rounded border border-light-gray m-1 p-4" v-for="segment in virus.segments"
          :key="segment.label">
          <div class="row">
            <div class="col col-6">
              <h5 class="card-title">{{ segment.label }}</h5>
            </div>
            <div class="col col-6">
              <router-link class="btn btn-outline-dark btn-sm float-right"
                :to="'/3dproteomeviewer/' +activeFamilyLbl+ '/' + virus.label.toLowerCase() + '/' + segment.label.toLowerCase()"
                :title="'View segment ' + segment.label">View</router-link>
            </div>
          </div>
          <div class="row">
            <div class="col col-12">
              <SegmentPreview :name="virus.label.toLowerCase() + '_' + segment.label.toLowerCase()"
                :basePath="getUrlPrefix() + virus.label.toLowerCase() + '/' + segment.label.toLowerCase()"
                :clickRoute="'/3dproteomeviewer/'  +activeFamilyLbl+ '/' + virus.label.toLowerCase() + '/' + segment.label.toLowerCase()"
                :dataServer="'https://data.pathogens3d.org/'">
              </SegmentPreview>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>

export default {
  name: "app",
  mixins: [],
  components: {
    SegmentPreview: () => import('../components/SegmentPreview.vue')
  },
  data: function () {
    return {
      overviewURL: "https://data.pathogens3d.org/overview.json",
      overviewData: null,
      activeFamilyLbl: "bunyaviruses"
    };
  },
  watch: {

  },
  mounted: function () {
    this.getOverviewData(); // Fetch data on component mount

  },
  computed: {
    activeFamily() {
      return this.overviewData.families.find(family => family.label.toLowerCase() === this.activeFamilyLbl);
    }
  },
  methods: {
    getUrlPrefix() { // later use same method from SharedMixin
      return  this.activeFamilyLbl+ '/'
    },
    getOverviewData: function () {
      fetch(this.overviewURL)
        .then((response) => response.json())
        .then((data) => {
          this.overviewData = data;
          this.activeFamilyLbl = this.overviewData.families[0].label.toLowerCase();
        });
    }
  }
};
</script>

<style>
.disable-link {
  pointer-events: none;
}

.border-light-gray {
  border-color: gray;
}

.tiny {
  font-size: 0.8em;
}
.nav-link.active{
  font-weight: bold;
  border-width: 2px;
}
</style>
